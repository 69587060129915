@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "react-phone-number-input/style.css";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x:hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.loading_overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 99999;
}

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .error-text {
    margin-top: 2px;
    color: rgb(241, 122, 33);  
  }

  .custom-form-datepicker {
    margin-bottom: -20px !important; /* Overrides any default styling */
  }

  /* Hide scrollbar but allow scrolling */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
  
  /* Reusable button styles */
@layer components {
  .form-button {
    @apply px-8 py-4 rounded-md text-white bg-primary hover:bg-secondary transition-all max-w-[250px] w-full mt-12;
  }
}